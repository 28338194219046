@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-schedule/styles/material.css';
@import '../node_modules/@progress/kendo-theme-default/dist/all.css';
/* .k-invalid */
.sanket-patel.k-input-md.k-rounded-md.k-input-solid.k-invalid.k-required {
  border-color: #ff1900 !important;
}
.k-Invalid-error {
  color: #ec0000;
  line-height: 1.5;
  font-size: 0.75rem;
  font-family: Public Sans, sans-serif;
  font-weight: 400;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.MuiButtonBase-root.MuiChip-root.MuiChip-filled {
  background-color: #65c8d0 !important;
  color: white;
}
.MuiButtonBase-root.MuiChip-root.MuiChip-filled svg {
  fill: white !important;
}
div#js-licensing {
  display: none !important;
}
.e-current-day,
.e-toolbar-item.e-active-view .e-tbar-btn-text,
.e-btn.e-today.e-flat.e-primary.e-css,
.e-current-time {
  color: #65c8d0 !important;
}
.e-current-date div,
.e-cell.e-selected span {
  background-color: #65c8d0 !important;
}
.e-schedule .e-vertical-view .e-previous-timeline {
  border-top: 1px dotted #65c8d0 !important;
}
.e-schedule .e-vertical-view .e-current-timeline {
  border-top: 1px solid #65c8d0 !important;
}
.css-tow1hb {
  padding-bottom: 0px !important;
}
.signatureCanvas {
  border: 1px solid gray;
  width: 100%;
  min-height: 100%;
  background-color: white;
}
.popup-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
.text_area {
  border: 1px solid rgb(205, 202, 202) !important;
  background: transparent !important;
}
.text_area:hover {
  border: 1px solid rgb(21, 21, 21) !important;
}
.text_area:focus-visible {
  border: 1px solid #65c8d0 !important;
  outline: none !important;
}
label.css-149i3wr-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 1.1rem !important;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-4 {
  margin-block: 15px;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-2 {
  padding-top: 0px;
}
.k-input-solid {
  border: 1px solid rgb(205, 202, 202) !important;
  background: transparent !important;
}
.k-input-solid:hover {
  border: 1px solid rgb(21, 21, 21) !important;
}
.k-chip.k-chip-md.k-rounded-md.k-chip-solid.k-chip-solid-base {
  background-color: #65c8d0 !important;
  color: white;
  height: 32px;
  width: auto;
  border-radius: 50px;
  margin-right: 10px !important;
}
.k-animation-container.k-animation-container-relative {
  z-index: 9999 !important;
  position: absolute !important;
}
.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeSmall {
  border-bottom: unset !important;
}
.password_visibale_icon {
  font-size: 1.1rem !important;
}
.error_msg {
  color: #ff4842 !important;
}

.k-input-values {
  color: black !important;
}
.k-chip-actions{
  display: none !important;
}
.k-list-item.k-focus {
  line-height: 1.5 !important;
  font-size: 1rem !important;
  color: #212b36 !important;
}

.k-list-md .k-list-item {
  line-height: 1.5 !important;
  font-size: 1rem !important;
  font-family: Public Sans, sans-serif !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  color: #212b36 !important;
}

.k-list-item.k-selected,
.k-selected.k-list-optionlabel {
  color: #212b36 !important;
  background-color: transparent !important;
}
.k-list-item:focus,
.k-list-optionlabel:focus,
.k-list-item.k-focus,
.k-focus.k-list-optionlabel {
  box-shadow: unset !important;
}
.k-list-md .k-list-item:hover {
  background-color: rgba(145, 158, 171, 0.08) !important;
}
.k-chip-content .k-chip-label {
  font-size: 13px !important;
  line-height: 23px !important;
  font-weight: 400 !important;
}
.k-list-ul {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.k-multiselect.k-input.k-input-md.k-rounded-md.k-input-solid {
  border: 1px solid rgba(145, 158, 171, 0.32) !important;
}
.k-input-solid:focus,
.k-input-solid.k-focus {
  border-color: unset !important;
  box-shadow: unset !important;
}

.k-multiselect.k-input.k-input-md.k-rounded-md.k-input-solid:hover {
  border: 1px solid #212b36 !important;
}

.k-multiselect.k-input.k-input-md.k-rounded-md.k-input-solid.k-focus {
  border: 2px solid #65c8d0 !important;
}
.k-input-values {
  padding: 0px 14px !important;
}
.k-icon {
  font-size: 19px !important;
}
.k-list-item.k-selected.k-focus {
  font-weight: 500 !important;
}
.error-input-border .MuiOutlinedInput-notchedOutline {
  border-color: #ff4842 !important;
}
@media screen and (max-width: 899px) {
  .k-multiselect.k-input.k-input-md.k-rounded-md.k-input-solid {
    width: 100% !important;
  }
}
